import React from "react"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <div>
      <SEO title="Maintenance" />
      <h1>Maintenance</h1>
    </div>
  )
}
export default IndexPage
